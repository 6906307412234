import React, { useState, useRef, useEffect } from 'react';
import axios from "axios";
import zoomPlugin from "chartjs-plugin-zoom";
import { Button, Modal } from "react-bootstrap";
import { Chart } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useHistory } from "react-router-dom";

import tableViewIcon from "../../assets/img/svg/graph-table-view.svg";
import tileViewIcon from "../../assets/img/svg/graph-tile-view.svg";
import fastBackwardIcon from "../../assets/img/svg/fast-backward-icon.svg";
import backwardIcon from "../../assets/img/svg/backward-icon.svg";
import playIcon from "../../assets/img/svg/play-icon.svg";
import fastForwardIcon from "../../assets/img/svg/fast-forward-icon.svg";
import forwardIcon from "../../assets/img/svg/forward-icon.svg";
import HeatMapGraph from './HeatMapGraph';
import './recording2.css';

const Recording2UpdatedV1 = (props) => {
  const params = useParams();
  const history = useHistory();

  const [startRange, setStartRange] = useState(0);
  const [endRange, setEndRange] = useState(0);
  const [value, setValue] = useState([0, 1000]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setStartRange(newValue[0]);
    setEndRange(newValue[1]);
  };

  const [lffValue, setLffValue] = useState("Select");
  const [hffValue, setHffValue] = useState("Select");
  const [notchValue, setNotchValue] = useState("Select");
  const [alert, setAlert] = useState();

  const [charts, setCharts] = useState([]);
  const chartRefs = useRef([]);
  const [dataCount, setDataCount] = useState(0);
  const [dataLength, setDataLength] = useState(null);
  const [eegData, setEegData] = useState([]);
  const [patientData, setPatientData] = useState();
  const [genderData, setGenderData] = useState();
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [conditionData, setConditionData] = useState();
  const [filteredEegData, setFilteredEegData] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showPwelchPlot, setShowPwelchPlot] = useState(0);
  const [pwelchChart, setPwelchChart] = useState(null);
  const [pwelchEegData, setPwelchEegData] = useState([]);
  const [pwelchFilteredData, setPwelchFilteredData] = useState(null);
  const [pwelchIsFilter, setPwelchIsFilter] = useState(0);
  const [pwelchLoading, setPwelchLoading] = useState(true);
  const [pwelchAlert, setPwelchAlert] = useState();
  const isMounted = useRef(false);

  // For Spectogram
  const [heatMapUrl, setHeatMapUrl] = useState();
  const [isHeatMap, setIsHeatMap] = useState(false);

  const token = useSelector((state) => state.loginData.token);
  Chart.register(zoomPlugin);

  const [requestStatus, setRequestStatus] = useState(false);

  // For Modal to save graph values
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isDataSaved, setIsDataSaved] = useState(false);

  const [graphView, setGraphView] = useState('tile');
  const [activeTab, setActiveTab] = useState('notes');
  const [relatedIds, setRelatedIds] = useState('notes');

  const toggleGraphView = (view) => setGraphView(view);
  const toggleActiveTab = (tab) => setActiveTab(tab);

  function handleSubmit(e) {
    e.preventDefault();
    setIsDataSaved(true);
    if (startRange < endRange) {
      setRequestStatus(true);
      axios.post(`${process.env.REACT_APP_BASE_URL}/save_recordings`, {
        patient_visit_id: params.id,
        start_time: startRange,
        end_time: endRange,
        clinical_condition: e.target[0].value,
      }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }).then((response) => {
        setRequestStatus(false);
        if (response.data.code === 401) {
          localStorage.clear();
          window.location.reload();
        } else {
          if (response.data.status === false) {
            setIsDataSaved(false);
            toast.error(response.data.data);
          } else {
            setIsDataSaved(false);
            toast.success("Data saved successfully");
            setShow(false);
          }
        }
      });
    } else {
      toast.error("Start range should be less than End range");
    }
  }

  function handleUpdateGraph(dataCount) {
    const actualData1 = eegData;
    const newCharts = [];

    actualData1.forEach((item, index) => {
      const canvasRef = chartRefs.current[index];
      const location = (item.patient_data?.location || "N/A").split('-')[0] || "";
      let chart = charts[index];
      if (chart) {
        chart.destroy();
        const newChart = createGraph(canvasRef, item, `CH1_Voltage`, location, dataCount);
        canvasRef.chart = newChart;
        newCharts.push(newChart);
      }
    });
    setCharts(newCharts);
    console.log("CHARTS CREATED UPDATED");
  }


  const handleBackward = () => {
    const newCount = dataCount - 1;
    console.log("Handle backward ", newCount);
    setDataCount(newCount);
    setValue([0, 1000]);
    handleUpdateGraph(newCount);

  }

  const handleForward = () => {
    console.log("lengths: ", dataCount,  dataLength);
    const newCount = dataCount + 1;
    console.log("Handle forward ", newCount);
    setDataCount(newCount);
    setValue([0, 1000]);
    handleUpdateGraph(newCount);
  }
  
  const handleBack = () => {
    window.history.back();
  };


  // Function to create a graph
  const createGraph = (canvasRef, data, label, location, dataCount) => {
    if (!canvasRef) {
      console.error("Canvas reference is null or undefined");
      return;
    }

    const chart = new Chart(canvasRef, {
      type: "line",
      data: {
        labels: data.length === 0 ? [] : data.length[dataCount],
        datasets: [
          {
            label: label,
            data: data.length === 0 ? [] : isFilter ? filteredEegData[label][dataCount] : data[label][dataCount],
            backgroundColor: "rgba(0, 0, 0, 0)", 
            borderColor: "rgba(0, 0, 0, 1)", 
            borderWidth: 0.4,
            pointRadius: 0,
          },
        ]
      },
      width: 400,
      height: 1000,
      options: {
        scales: {
          x: {
            ticks: {
              type: 'linear',
              stepSize: 1,
              maxTicksLimit: 10, 
              beginAtZero: true,
            },
            title: {
              display: true,
              text: "Time (seconds)",
            },
          },
          y: {
            title: {
              display: true,
              text: [
                "EEG (uV)", 
                location,
              ],
            },
          },
        },
        normalized: true,
        maintainAspectRatio: false,
        animation: false,
        spanGaps: true,
        datasets: {
          line: {
            pointRadius: 0, 
          },
        },
        elements: {
          point: {
            radius: 0, 
          },
        },
        plugins: {
          decimation: {
            enabled: true,
          },
          zoom: {
            pan: {
              enabled: true,
              mode: "x",
              rangeMin: { x: 0 },
              rangeMax: { x: 100 },
            },
            zoom: {
              wheel: {
                enabled: true,
                mode: "xy",
                rangeMin: { x: 0 },
                rangeMax: { x: 100 },
              },
              pinch: {
                enabled: true,
              },
              mode: "x",
            },
          },
        },
      },
    });
    return chart
  };

  // Fetch EEG data and related visit records
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch related visit records
        const relatedResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/patient/related-visit-record/${params.id}`, {
          headers: {
            Authorization: token,
          },
        });
        
        if (relatedResponse.data.status && relatedResponse.data.data) {
          const relatedIds = relatedResponse.data.data.map(record => record.id);
          setRelatedIds(relatedIds)
          
          // Fetch EEG data for related records
          const eegResponse = await axios.post(`${process.env.REACT_APP_BASE_URL}/eeg_file_v2`, {
            ids: relatedIds,
          }, {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          });
          
          const actualData = eegResponse.data.data[0];
          const actualData1 = eegResponse.data.data;
          if (eegResponse.data.code === 401) {
            localStorage.clear();
            window.location.reload();
          } else {
            if (eegResponse.data.message !== "error") {
              const patient_gender = +actualData.patient_data?.gender;
              const genderDisplay =
              patient_gender === 1 ? "M" :
              patient_gender === 2 ? "F" : "N/A";
              const patient_condition = +actualData.patient_data?.clinical_condition;
              const conditionDisplay =
              patient_condition === 1 ? "Seizure" :
                patient_condition === 2 ? "Concussions" :
                patient_condition === 3 ? "Stroke" : "N/A";
                const timestamp = actualData.patient_data?.timestamp;
                
                if (timestamp) {
                  const [date, time] = timestamp.split(' ');
                setDate(date); 
                setTime(time); 
              }
              setGenderData(genderDisplay);
              setConditionData(conditionDisplay);
              setPatientData(actualData.patient_data);
              setEegData(actualData1);
              setDataLength(actualData1.length);
              setLoading(false);
              setShowPwelchPlot(1);
              
              // Create multiple graphs
              const newCharts = [];
              for (let i = 0; i < actualData1.length; i++) {
                const canvasRef = chartRefs.current[i];
                const location = `${(actualData1[i].patient_data?.location || "N/A").split('-')[0] || ""}`;
                newCharts.push(createGraph(canvasRef, actualData1[i], `CH1_Voltage`, location, dataCount));

              }
              
              setTimeout(() => {
                setCharts(newCharts);
                
              }, 3000);

            } else {
              setAlert(eegResponse.data.message);
            }
          }
        } else {
          setAlert(relatedResponse.data.message);
        }
      } catch (error) {
        toast.error("Error fetching data", { autoClose: 3000, position: "top-right" });
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Apply filters
  const applyFilters = () => {
    setIsHeatMap(false);
    setIsDataSaved(true);
  
    axios.post(`${process.env.REACT_APP_BASE_URL}/filters_v1`, {
      patient_visit_id: relatedIds,
      LFF: lffValue,
      HFF: hffValue,
      Notch: notchValue,
    }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    .then((response) => {
      setIsDataSaved(false);
      const { code, data, status } = response.data;
      
      if (code === 200) {
        const actualData1 = data;
        const newCharts = [];
  
        actualData1.forEach((item, index) => {
          const canvasRef = chartRefs.current[index];
          const location = (item.patient_data?.location || "N/A").split('-')[0] || "";
          console.log("Canves REF: ", canvasRef);
          let chart = charts[index];
          if (chart) {
            console.log(`Destroying chart at index ${index}`);
            chart.destroy();
            const newChart = createGraph(canvasRef, item, `CH1_Voltage`, location, dataCount);
            canvasRef.chart = newChart; 
            newCharts.push(newChart);
          }
        });
  
        setTimeout(() => {
          setCharts(newCharts);
        }, 3000);
      } else if (code === 401) {
        localStorage.clear();
        window.location.reload();
      } else {
        if (status === false) {
          // Handle false status appropriately
        } else {
          setLoading(true);
          setIsFilter(false);
          setFilteredEegData([]);
          setFilteredEegData(data);
          setIsFilter(true);
          setLoading(false);
        }
      }
    })
    .catch((error) => {
      console.error("Error applying filters:", error);
      setIsDataSaved(false);
      // Handle error appropriately, e.g., show a notification to the user
    });
  };
  

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {alert ? (
        <Modal.Dialog>
          <Modal.Header>
            <Modal.Title>Data Not Available</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>No data was found for this patient visit</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={(e) => {
                history.goBack();
              }}
            >
              Go Back
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      ) : (
        <div className='recording2'>
          <div className='recording-graph-panel'>
            <div className='info-container'>
                <div className='personal-info'>
                <button 
                  // className="btn" 
                  onClick={handleBack} 
                  style={{
                    background: 'transparent',
                    color: 'black',
                    fontSize: '22px',
                    border: 'none',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '1rem'
                  }}
                >
                  <span style={{width: '20px', marginRight: '1rem'}}>

                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
                  </span>
                  Back
                </button>

                <h1>{patientData?.name}</h1>
                <div className='info-wrapper'>
                  <div className='info'>
                    <p>Patient#: </p>
                    <p>{patientData?.id_number || "N/A"}</p>
                  </div>
                  <div className='info'>
                    <p>Age / Gender: </p>
                    <p>{patientData?.age || "N/A"} / {genderData || "N/A"}</p>
                  </div>
                  <div className='info'>
                    <p>Condition: </p>
                    <p>{conditionData || "N/A"}</p>
                  </div>
                  <div className='info'>
                    <p>Date of Visit: </p>
                    <p>{date || "N/A"}</p>
                  </div>
                  <div className='info'>
                    <p>Time of Visit: </p>
                    <p>{time || "N/A"}</p>
                  </div>
                </div>
              </div>
              <div className='btns-container'>
                <button className="button btn-invert">Add Notes</button>
                <button className="button btn-default">Share Report</button>
              </div>
            </div>
            <div className='eeg-graph-panel'>
              <div className='eeg-header'>
                <div className='header-stats'>
                  <div className='header-stat'>
                    <p>Display: <span>10s</span></p>
                  </div>
                  <div className='header-stat'>
                    <p>Scale: <span>10uv/mm</span></p>
                  </div>
                  <div className='header-stat'>
                    <p>LFF: </p>
                    <div className="dropdown" style={{ paddingLeft: "4px" }}>
                      <button
                        className="btn green-btn2 btn-sm dropdown-toggle"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        defaultValue="1Hz"
                        style={{ fontWeight: "bold", fontSize: "10" }}
                      >
                        {lffValue}
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                        <button className="dropdown-item" type="button" onClick={() => setLffValue("N/A")}>
                          N/A
                        </button>
                        <button className="dropdown-item" type="button" onClick={() => setLffValue("1Hz")}>
                          1Hz
                        </button>
                        <button className="dropdown-item" type="button" onClick={() => setLffValue("5Hz")}>
                          5Hz
                        </button>
                        <button className="dropdown-item" type="button" onClick={() => setLffValue("15Hz")}>
                          15Hz
                        </button>
                        <button className="dropdown-item" type="button" onClick={() => setLffValue("20Hz")}>
                          20Hz
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='header-stat'>
                    <p>HFF: </p>
                    <div className="dropdown" style={{ paddingLeft: "4px" }}>
                      <button
                        className="btn green-btn2 btn-sm dropdown-toggle"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        defaultValue="HFF4"
                        style={{ fontWeight: "bold", fontSize: "10" }}
                      >
                        {hffValue}
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                        <button className="dropdown-item" type="button" onClick={() => setHffValue("N/A")}>
                          N/A
                        </button>
                        <button className="dropdown-item" type="button" onClick={() => setHffValue("15Hz")}>
                          15 Hz
                        </button>
                        <button className="dropdown-item" type="button" onClick={() => setHffValue("30Hz")}>
                          30 Hz
                        </button>
                        <button className="dropdown-item" type="button" onClick={() => setHffValue("40Hz")}>
                          40 Hz
                        </button>
                        <button className="dropdown-item" type="button" onClick={() => setHffValue("70Hz")}>
                          70 Hz
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='header-stat'>
                    <p>Notch: </p>
                    <div className="dropdown" style={{ paddingLeft: "4px" }}>
                      <button
                        className="btn btn-sm green-btn2 dropdown-toggle"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        defaultValue="HFF4"
                        style={{ fontWeight: "bold", fontSize: "10" }}
                      >
                        {notchValue}
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                        <button className="dropdown-item" type="button" onClick={(e) => { setNotchValue("N/A"); }}>
                          N/A
                        </button>
                        <button className="dropdown-item" type="button" onClick={(e) => { setNotchValue("50Hz"); }}>
                          50 Hz
                        </button>
                        <button className="dropdown-item" type="button" onClick={(e) => { setNotchValue("60Hz"); }}>
                          60 Hz
                        </button>
                      </div>
                    </div>
                    <button
                      className="btn green-btn32 btn-sm"
                      type="button"
                      style={{
                        fontWeight: "bold",
                        fontSize: "6",
                      }}
                      disabled={requestStatus}
                      onClick={() => {
                        applyFilters(lffValue, hffValue, notchValue);
                      }}
                    >
                      Apply Filters
                    </button>
                  </div>
                </div>
                <div className='header-view-container'>
                  <img src={tileViewIcon} alt="tile view" onClick={() => toggleGraphView('tile')} className={graphView === 'tile' ? 'header-active-view' : ''} />
                  <img src={tableViewIcon} alt="table view" onClick={() => toggleGraphView('table')} className={graphView === 'table' ? 'header-active-view' : ''} />
                </div>
              </div>
              <div className='eeg-graph'>
                {eegData.map((data, index) => (
                  <div key={index} className="graph-box">
                    <canvas ref={(el) => (chartRefs.current[index] = el)} height="200" />
                  </div>
                ))}
              </div>
              <div className='eeg-graph-controls'>
                <img src={fastBackwardIcon} alt="fast backward" />
                <button
                  disabled={dataCount === 0 ? true : false}
                  className='control-button'
                  onClick={handleBackward }
                >
                  <img src={backwardIcon} alt="backward" />
                </button>
                <img src={playIcon} alt="play" />
                <button
                  disabled={dataCount === dataLength - 1 ? true : false}
                  className='control-button'
                  onClick={handleForward}
                >
                  <img src={forwardIcon} alt="forward" />
                </button>
                <img src={fastForwardIcon} alt="fast forward" />
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
        <HeatMapGraph></HeatMapGraph>
      </div>
    </div>
  );
};

export default Recording2UpdatedV1;
